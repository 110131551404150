export const exportCsv = (data) => {
  let csvContent = 'data:text/csv;charset=utf-8,'
  var universalBOM = '\uFEFF'
  csvContent += universalBOM
  csvContent +=
    // 'Tipas;Kliento kodas;Valstybinis numeris;Apdraustų dienų skaičius;Įvykių skaičius;Įvykiai;\n'
    'Stage;Stage Change Date;Client;KRD Claim;KRD Insurance Provider;NA Claim;NA Insurance Provider;Insured Person;Birth Date;Beneficiary IBAN;Expenses;Payout Received;Incident Date;Incident Location;Hospital Contacts;Outcome;Duration\n'
  csvContent += data
    .map((item) =>
      Object.values(item)
        .map((el) => {
          if (typeof el === 'string' && el.includes('#')) {
            return el.replaceAll('#', ' ')
          } else {
            return el
          }
        })
        .join(';')
    )
    .join('\n')
    .replace(/(^\[)|(\]$)/gm, '')

  const csvData = encodeURI(csvContent)
  return csvData
}
